import './App.css';
import React, { useState, useEffect } from 'react';

import { getScoreChartData } from "./api";
import Loader from "./components/loader";
import { ReactComponent as StarIcon } from "./images/icons/star_black_24dp.svg";
import { ReactComponent as StarOutlineIcon } from "./images/icons/star_outline_black_24dp.svg";
import Picture from "./components/picture";
import { padLeft } from "./helpers/pad.js";

function ScoreChart() {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const i = setInterval(() => {
      getScoreChartData().then(setChartData);
    }, 5000);

    return () => clearInterval(i);
  }, [setChartData]);

  if(chartData === null) {
    return (
        <div className="Account">
          <Loader />
        </div>
    );
  }

  return (
    <div className="Account" style={{ padding: 32, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Chart {...chartData} />
    </div>
  );
}

const DATE_LABELS = ["Lun", "Mar", "Mer", "Jeu", "Ven", "Sam", "Dim"];

function Chart({ start, lines, type, users }) {
  // Columns for non date-based grids
  let columns = [{ id: 0, value: 0, label: "Points" }];
  if(type > 0) {
    // Date based grid
    columns = [];
    for(let i = 0; i < type; i++) {
      columns.push({ id: i, value: start + (i * 86400), label: DATE_LABELS[i % DATE_LABELS.length] });
    }
  }

  const date = !!start && new Date(start * 1000);
  const maxStars = lines.reduce((m, l) => l.maxPoints > m ? l.maxPoints : m, 0) * columns.length * users.length;
  const starSize = (Math.floor(document.body.getBoundingClientRect().width * 0.6 / maxStars / 12) * 12) + 'px';

  return (
      <div style={{ width: '100%' }}>
        <div className="Grid-content" style={{ borderRadius: '32px' }}>
          {date && (
              <div className="Grid-header-description">
                <div className="Grid-header-description-text" style={{ fontSize: '1.8rem', marginBottom: 16 }}>Semaine du {padLeft(date.getDate(), 2)}/{padLeft(date.getMonth() + 1, 2)}/{padLeft(date.getFullYear(), 4)}.</div>
              </div>
          )}
          {!lines.length && (
              <div>Aucune action définie.</div>
          )}
          <div className="Grid-line Grid-line-header">
            <div className="Grid-line-cell Grid-line-name"></div>
            {columns.map(c => <div key={c.id} className="Grid-line-cell Grid-line-value">{c.label}</div>)}
          </div>
          <div className="Grid-line Grid-line-header">
            <div className="Grid-line-cell Grid-line-name"></div>
            {columns.map(c => users.map(u =>
                <div key={c.id + '-' + u.id} className="Grid-line-cell Grid-line-value">
                  <div>
                    <div style={{ display: 'inline-block', width: starSize, height: starSize }}>
                      <Picture {...u} />
                    </div>
                  </div>
                  <div>
                    {u.firstname}
                  </div>
                </div>
            )).flat()}
          </div>
          {lines.map(line => {
            return (
                <div key={line.id} className="Grid-line">
                  <div className="Grid-line-cell Grid-line-name">
                    {line.name}
                  </div>
                  {columns.map(c => users.map(u => {
                    const stars = [];
                    const points = ((line.points || []).find(p => p[1] === c.value && p[2] === u.id) || [])[0] || 0;
                    for(let i = 0; i < line.maxPoints; i++) {
                      if(i < points) {
                        stars.push(<StarIcon key={i} fill="#FFDF00" width={starSize} height={starSize} />);
                      } else {
                        stars.push(<StarOutlineIcon key={i} fill="#CCCCCC" width={starSize} height={starSize} />);
                      }
                    }

                    return (
                        <div key={c.id + '-' + u.id} className="Grid-line-cell Grid-line-value">
                          {stars}
                        </div>
                    );
                  })).flat()}
                </div>
            );
          })}
        </div>
      </div>
  );
}

export default ScoreChart;
