import { useState, useEffect, useContext, createContext } from 'react';

/* Vars */
const ENDPOINT = "/svc";

/* Context */
let store = {
    token: (window.localStorage && window.localStorage.getItem("token")) || null,
};
let apiListeners = [];
const ApiContext = createContext(store);

const setStore = (s) => {
    if(store !== s) {
        // Token persistence
        if(s.token !== store.token) {
            if (window.localStorage) {
                if (s.token) {
                    window.localStorage.setItem("token", s.token);
                } else {
                    window.localStorage.removeItem("token");
                }
            }
        }
        // Update store
        store = s;
        apiListeners.forEach(apiListener => apiListener(s));
    }
}

export const ApiProvider = ({ children }) => {
    const [state, setState] = useState(store);
    useEffect(() => {
        const listener = (state) => setState(state);
        apiListeners.push(listener);

        return () => {
            apiListeners = apiListeners.filter(l => l !== listener);
        };
    }, []);

    return <ApiContext.Provider value={state} children={children} />;
};

/* Helpers */
const checkLoggedIn = (cb) => (...params) => {
    if(!store.token) {
        throw new Error("Not logged in.");
    }
    return cb(store.token, ...params);
};

const processResponse = (response) => {
    if(response.status === 200) {
        return response.json();
    } else if(response.status === 403 || response.status === 401) {
        // 403 is unauthorized, and 401 is session expired
        let loginError = store.loginError;
        if(!loginError) {
            loginError = "NOT_AUTHORIZED";
            if(response.status === 401) {
                loginError = "SESSION_EXPIRED";
            }
        }
        setStore({ ...store, token: null, loginError });
        return new Promise((resolve, reject) => reject());
    } else {
        // Throw error if other status (400)
        return new Promise((resolve, reject) => response.json().then(json => reject(json)));
    }
};

const doGet = (method, token) => fetch(ENDPOINT + method, {
    method: "GET",
    headers: token ? {
        "Authorization": "Token token=\"" + token + "\"",
    } : {},
});

/* Login */
export const useLoggedIn = () => {
    const state = useContext(ApiContext);
    return [!!state.token, state.loginError];
}

export const login = (login, password) => {
    return fetch(ENDPOINT + "/login", {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ login, password }),
    }).then(processResponse).then(result => {
        setStore({ ...store, token: result.token || null, loginError: null });
        return !!result.token;
    }).catch(_ => {
        setStore({ ...store, token: null, loginError: "INVALID_LOGIN" });
    });
}

export const logout = () => {
    return doGet("/logout").then(processResponse).then(result => {
        if(result.success) {
            setStore({ ...store, token: null, loginError: "LOGOUT_SUCCESSFUL" });
            return true;
        }
        return false;
    }).catch(_ => {
        setStore({ ...store, token: null, loginError: "LOCAL_LOGOUT_ONLY" });
    });
}

/* User */
export const getUser = checkLoggedIn((token) => doGet("/user", token).then(processResponse));
export const getRecentContacts = checkLoggedIn((token) => doGet("/recentContacts", token).then(processResponse));
export const updatePicture = checkLoggedIn((token, picture) => fetch(ENDPOINT + "/updatePicture", {
    method: "POST",
    body: picture,
    headers:{
        'Content-Type': 'image/png',
        "Authorization": "Token token=\"" + token + "\"",
    },
}).then(processResponse))

/* Accounts */
export const getAccounts = checkLoggedIn((token) => doGet("/accounts", token).then(processResponse));

/* Transactions */
export const getLatestTransactions = checkLoggedIn((token) => doGet("/latestTransactions", token).then(processResponse));
export const getTransactions = checkLoggedIn((token, account, start) => doGet("/transactions/" + account + (start ? "?start=" + start : ""), token).then(processResponse));
export const doTransaction = checkLoggedIn((token, source, destination, amount, description) => {
    return fetch(ENDPOINT + "/transaction", {
        method: "POST",
        body: JSON.stringify({ source, destination, amount, description }),
        headers:{
            'Content-Type': 'application/json',
            "Authorization": "Token token=\"" + token + "\"",
        },
    }).then(processResponse);
});

/* Subscription */
export const subscribe = checkLoggedIn((token, sub) => fetch(ENDPOINT + "/subscription", {
    method: "POST",
    body: JSON.stringify(sub),
    headers:{
        'Content-Type': 'application/json',
        "Authorization": "Token token=\"" + token + "\"",
    },
}).then(processResponse));

export const unsubscribe = checkLoggedIn((token, sub) => fetch(ENDPOINT + "/removeSubscription", {
    method: "POST",
    body: JSON.stringify(sub),
    headers:{
        'Content-Type': 'application/json',
        "Authorization": "Token token=\"" + token + "\"",
    },
}).then(processResponse));

/* Rewards */
export const getRewardsSummary = checkLoggedIn((token, account) => doGet("/rewardsSummary/" + account, token).then(processResponse));
export const getRewards = checkLoggedIn((token, account) => doGet("/rewards/" + account, token).then(processResponse));
export const getLatestRewards = checkLoggedIn((token) => doGet("/latestRewards", token).then(processResponse));

export const doBuyReward = checkLoggedIn((token, account, reward, quantity) => {
    return fetch(ENDPOINT + "/reward", {
        method: "POST",
        body: JSON.stringify({ account, reward, quantity }),
        headers:{
            'Content-Type': 'application/json',
            "Authorization": "Token token=\"" + token + "\"",
        },
    }).then(processResponse);
});

/* Timers */
export const getTimers = checkLoggedIn((token, account) => doGet("/timers/" + account, token).then(processResponse));

/* Grids */
export const getGridsSummary = checkLoggedIn((token, userId) => doGet(userId ? "/gridsSummary/" + userId : "/gridsSummary", token).then(processResponse));
export const getGrid = checkLoggedIn((token, gridId, userId, date) => doGet("/grid/" + gridId + (userId ? "/" + userId : "") + (date ? "?date=" + date : ""), token).then(processResponse));
export const getRecentGridContacts = checkLoggedIn((token) => doGet("/recentGridContacts", token).then(processResponse));

export const doAttributePoint = checkLoggedIn((token, lineId, value, points, userId) => {
    return fetch(ENDPOINT + "/attributePoint", {
        method: "POST",
        body: JSON.stringify({ lineId, value, points, userId }),
        headers:{
            'Content-Type': 'application/json',
            "Authorization": "Token token=\"" + token + "\"",
        },
    }).then(processResponse);
});

/* Admin */
export const suggestContacts = checkLoggedIn((token, user) => doGet("/suggestContacts?user=" + encodeURIComponent(user), token).then(processResponse));
export const getContact = checkLoggedIn((token, user) => doGet("/getContact?user=" + encodeURIComponent(user), token).then(processResponse));
export const credit = checkLoggedIn((token, destination, amount, description, reference) => {
    return fetch(ENDPOINT + "/credit", {
        method: "POST",
        body: JSON.stringify({ destination, amount, description, reference }),
        headers:{
            'Content-Type': 'application/json',
            "Authorization": "Token token=\"" + token + "\"",
        },
    }).then(processResponse);
});
export const debit = checkLoggedIn((token, destination, amount, description) => {
    return fetch(ENDPOINT + "/debit", {
        method: "POST",
        body: JSON.stringify({ destination, amount, description }),
        headers:{
            'Content-Type': 'application/json',
            "Authorization": "Token token=\"" + token + "\"",
        },
    }).then(processResponse);
});

/* Score chart */
export const getScoreChartData = () => {
    return doGet("/scoreChartData").then(processResponse);
};