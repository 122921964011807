import React, { useState, useEffect, useRef } from 'react';
import { getUser, subscribe, unsubscribe, updatePicture } from "../../api";
import { Link } from "react-router-dom";
import { getRegistration, subscribeUser, unsubscribeUser } from '../../serviceWorkerRegistration';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop from 'react-image-crop';

import './index.css';
import { ReactComponent as SendIcon } from "../../images/icons/send_black_24dp.svg";
import { ReactComponent as PersonIcon } from "../../images/icons/person_black_24dp.svg";
import { ReactComponent as ImageIcon } from "../../images/icons/image_black_24dp.svg";
import { ReactComponent as CancelIcon } from "../../images/icons/cancel_black_24dp.svg";
import { ReactComponent as ArrowBackIcon } from "../../images/icons/arrow_back_black_24dp.svg";
import { ReactComponent as PasswordIcon } from "../../images/icons/password_black_24dp.svg";
import { ReactComponent as AlternateEmailIcon } from "../../images/icons/alternate_email_black_24dp.svg";
import chevronRightIcon from "../../images/icons/chevron_right_black_24dp.svg";
import fileUploadIcon from "../../images/icons/file_upload_black_24dp.svg";
import Loader from "../../components/loader";
import Picture from "../../components/picture";

function Home() {
  const [user, setUser] = useState(null);
  const [accountsExpanded, setAccountsExpanded] = useState(true);
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const [notificationsActive, setNotificationsActive] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0, width: 192, height: 192, aspect: 1 });

  useEffect(() => {
    Promise.all([
      getUser().then(user => () => setUser(user)),
      getRegistration().then(registration => registration && registration.pushManager.getSubscription()).then(sub => () => setNotificationsActive(!!sub)),
      new Promise(accept => setTimeout(() => accept(() => {}), 1000)),
    ]).then(cbs => cbs.forEach(cb => cb()));
  }, []);

  if(!user) {
    return (
      <div className="Profile">
        <Loader />
      </div>
    );
  }

  /* Portrait */
  const onImageLoad = (image) => {
    imgRef.current = image;
    setCrop({
      aspect: 1,
      x: (image.width / 2) - 64,
      y: (image.height / 2) - 64,
      width: 128,
      height: 128,
    });
    return false;
  };

  const onSelectFile = (e) => {
    const uploadInput = e.target;
    if (uploadInput.files && uploadInput.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => { setUpImg(reader.result); uploadInput.value = ""; });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const sendFile = () => {
    if (!crop || !imgRef.current) {
      return;
    }

    const image = imgRef.current;

    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width;
    canvas.height = crop.height;

    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // As a blob
    canvas.toBlob(blob => {
      setUser(null);
      setUpImg(null);
      setTimeout(() => updatePicture(blob).then(_ => {
        getUser().then(user => setUser(user));
      }).catch(_ => {
        getUser().then(user => setUser(user));
      }), 1);
    }, 'image/png', 1);
  };

  /* Notifications */
  const notificationsAvailable = window.isSecureContext && ('serviceWorker' in navigator) && ('PushManager' in window) && ('Notification' in window);
  const removeNotification = () => {
    unsubscribeUser().then(unsubscribe).then((response) => {
      if(response.success) {
        setNotificationsActive(false);
      }
    });
  };
  const subscribeNotifications = () => {
    if (window.Notification.permission === 'default') {
      window.Notification.requestPermission().then(() => {
        if (Notification.permission === 'granted') {
          subscribeUser().then(subscribe).then((response) => {
            if(response.success) {
              setNotificationsActive(true);
            }
          }).catch(e => {
            // registration failed
            console.log('subscribeUser failed with: ', e);
          });
        }
      });
    }
    if (Notification.permission === 'granted') {
      subscribeUser().then(subscribe).then((response) => {
        if(response.success) {
          setNotificationsActive(true);
        }
      }).catch(e => {
        // registration failed
        console.log('subscribeUser failed with: ', e);
      });
    }
  };

  return (
    <div className="Profile">
      <div className="Profile-header">
        <div className="Profile-header-title">
          <div className="Profile-header-title-icon">
            <Link to="/">
              <ArrowBackIcon fill="#FFFFFF" width="32px" height="32px" />
            </Link>
          </div>
          <div className="Profile-header-title-text">Mon Profil</div>
        </div>
        <div className="Profile-header-contact">
          <div className="Profile-header-contact-picture">
            <Picture {...user} />
          </div>
          <div className="Profile-header-contact-info">
            <div className="Profile-header-contact-info-name">{user.firstname} {user.lastname}</div>
            <div className="Profile-header-contact-info-email">{user.email}</div>
          </div>
        </div>
      </div>
      <div className="Profile-content">
        {/* TODO : Make a profile page :
          - Possibility to change password
          - Possibility to change email ?
        */}
        <div className={"Profile-content-card" + (accountsExpanded ? " Profile-content-card-expanded" : "")}>
          <div className="Profile-content-button" onClick={() => setAccountsExpanded(!accountsExpanded)}>
            <div className="Profile-content-button-icon">
              <PersonIcon fill="#108F38" />
            </div>
            <div className="Profile-content-button-text">
              Mes informations
            </div>
            <div className="Profile-content-button-trailing">
              <img src={chevronRightIcon} alt="" />
            </div>
          </div>
          <div className="Profile-content-card-content">
            {/*<Link to={"#"} className="Home-account-link">*/}
            <div className="Profile-account Profile-account-condensed Profile-content-button-disabled">
              <div className="Profile-account-picture-small">
                <PasswordIcon fill="#108F38" height="24px" width="24px" />
              </div>
              <div className="Profile-account-info">
                <div className="Profile-account-info-name">Changer mon mot de passe</div>
              </div>
            </div>
            {/*</Link>*/}
            {/*<Link to={"/admin/credit-debit"} className="Profile-account-link">*/}
              <div className="Profile-account Profile-account-condensed Profile-content-button-disabled">
                <div className="Profile-account-picture-small">
                  <AlternateEmailIcon fill="#108F38" height="24px" width="24px" />
                </div>
                <div className="Profile-account-info">
                  <div className="Profile-account-info-name">Changer mon adresse courriel</div>
                </div>
              </div>
            {/*</Link>*/}
          </div>
        </div>
        <div className="Profile-content-button">
          <div className="Profile-content-button-icon">
            <ImageIcon fill="#108F38" />
          </div>
          <div className="Profile-content-button-text">
            Changer mon portrait
          </div>
          <div className="Profile-content-button-trailing">
            <img src={fileUploadIcon} alt="" />
          </div>
          <input className="Profile-content-uploader" type="file" accept="image/*" onChange={onSelectFile} />
        </div>
        <div className={"Profile-content-button" + (notificationsAvailable ? "" : "Profile-content-button-disabled")}>
          <div className="Profile-content-button-icon">
            <SendIcon fill="#108F38" />
          </div>
          <div className="Profile-content-button-text">
            Notifications
          </div>
          <div className="Profile-content-button-trailing2">
            <div className={"Profile-switch-container " + (notificationsActive ? "Profile-switch-active" : "Profile-switch-inactive")} onClick={notificationsActive ? removeNotification : subscribeNotifications}>
              <div className="Profile-switch-thumb" />
            </div>
          </div>
        </div>
      </div>
      {upImg && (
        <div className="Profile-overlay">
          <ReactCrop
            src={upImg}
            crop={crop}
            minWidth={128}
            minHeight={128}
            keepSelection
            circularCrop
            onChange={crop => setCrop(crop)}
            onImageLoaded={onImageLoad}
          />
          <div className="Profile-overlay-cancel-buttons">
            <CancelIcon fill="#FFFFFF" width="32px" height="32px" onClick={() => setUpImg(null)} />
          </div>
          <div className="Profile-overlay-validation-buttons">
            <div className="Profile-overlay-button" onClick={sendFile}>Envoyer</div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;