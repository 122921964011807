import { ReactComponent as PaidIcon } from "../images/icons/paid_black_24dp.svg";
import { ReactComponent as SavingsIcon } from "../images/icons/savings_black_24dp.svg";
import { ReactComponent as HelpIcon } from "../images/icons/help_outline_black_24dp.svg";

export const categories = {
  "nocat": {
    "name": "Non catégorisé",
    "icon": HelpIcon,
  },
  "savings": {
    "name": "Épargne",
    "icon": SavingsIcon,
  },
  "salary": {
    "name": "Paie",
    "icon": PaidIcon,
    //"color": "red",
  },
};

export function getCategoryIcon(category) {
  return (category && categories[category] && categories[category].icon) || HelpIcon;
}

export function getCategoryName(category) {
  return (category && categories[category] && categories[category].name) || "Non catégorisé";
}

export function getCategoryColor(category) {
  return (category && categories[category] && categories[category].color) || "#108F38";
}