import { ReactComponent as PaidIcon } from "../images/icons/paid_black_24dp.svg";
import { ReactComponent as SavingsIcon } from "../images/icons/savings_black_24dp.svg";
import { ReactComponent as LocalActivityIcon } from "../images/icons/local_activity_black_24dp.svg";
import { ReactComponent as HelpIcon } from "../images/icons/help_outline_black_24dp.svg";
import { ReactComponent as BeachAccessIcon } from "../images/icons/beach_access_black_24dp.svg";

export const types = {
  "check": {
    "name": "Compte courant",
    "icon": PaidIcon,
    "decimal": true,
  },
  "savings": {
    "name": "Compte épargne",
    "icon": SavingsIcon,
    "decimal": true,
  },
  "privileges": {
    "name": "Compte privilèges",
    "icon": LocalActivityIcon,
  },
  "holidays": {
    "name": "Compte vacances",
    "icon": BeachAccessIcon,
    "decimal": true,
  },
};

export function getAccountIcon(type) {
  return (type && types[type] && types[type].icon) || HelpIcon;
}

export function getAccountName(type) {
  return (type && types[type] && types[type].name) || "Compte";
}

export function getShowDecimal(type) {
  return !!(type && types[type] && types[type].decimal);
}