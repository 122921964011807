import React from 'react';

import './index.css';
import olive from "../../images/olive.png";
import piggyBack from "../../images/piggy_back.png";
import piggyFront from "../../images/piggy_front.png";

function Loader({ text = "Chargement...", goingOut = false }) {
  return (
    <div className={"Loader" + (goingOut ? " Loader-out" : "")}>
      <div className="Loader-piggy" style={{ backgroundImage: "url(" + piggyBack + ")" }} />
      <div className="Loader-olive" style={{ backgroundImage: "url(" + olive + ")" }} />
      <div className="Loader-olive" style={{ backgroundImage: "url(" + olive + ")", animationDelay: ".75s" }} />
      <div className="Loader-olive" style={{ backgroundImage: "url(" + olive + ")", animationDelay: "1.5s" }} />
      <div className="Loader-piggy" style={{ backgroundImage: "url(" + piggyFront + ")" }} />
      <div className="Loader-text">{text}</div>
    </div>
  );
}

export default Loader;