import React, { useEffect, useState } from 'react';
import { Link, Redirect } from "react-router-dom";
import Loader from "../../../components/loader";

import { getContact, getGridsSummary, getRecentGridContacts, getUser, suggestContacts } from "../../../api";
import { ReactComponent as ArrowBackIcon } from "../../../images/icons/arrow_back_black_24dp.svg";
import Picture from "../../../components/picture";
import { ReactComponent as StarOutlineIcon } from "../../../images/icons/star_outline_black_24dp.svg";

let timeout;

function AttributePoints() {
  const [user, setUser] = useState(null);

  // 1. Suggestion
  const [suggestionTerm, setSuggestionTerm] = useState("");
  const [suggestions, setSuggestions] = useState(null);
  // 2. Contact
  const [grids, setGrids] = useState(null);
  const [contact, setContact] = useState(null);
  const [recentContacts, setRecentContacts] = useState(null);

  useEffect(() => {
    Promise.all([
      getUser().then(user => () => setUser(user)),
      getRecentGridContacts().then(contacts => () => setRecentContacts(contacts)),
      new Promise(accept => setTimeout(() => accept(() => {}), 1000)),
    ]).then(cbs => cbs.forEach(cb => cb()));
  }, []);

  if(!user) {
    return (
      <div className="Transfer">
        <Loader />
      </div>
    );
  }

  if(!(user.rights & 0b0010)) {
    // TODO : Make an unauthorized component ?
    return <Redirect to="/" />;
  }

  const handleSuggestContacts = (c) => {
    setSuggestionTerm(c);
    if(c) {
      if(timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        suggestContacts(c).then(suggestions => setSuggestions(suggestions));
      }, 1000);
    } else {
      setSuggestions(null);
    }
  };

  const selectContact = (c, no) => {
    if(c) {
      setSuggestions(null);
      getContact(c).then(infos => {
        // TODO : Show error if no contact ? (wrong QR for example)
        setContact(infos.contact);
        setSuggestionTerm(infos.contact ? infos.contact.login : "");
        setGrids(null);
        if(infos.contact) {
          getGridsSummary(infos.contact.id).then(grids => setGrids(grids));
        }
      });
    }
  };

  // Form page
  return (
      <div className="Transfer">
        <div className="Transfer-header">
          <div className="Transfer-header-title">
            <div className="Transfer-header-title-icon">
              <Link to="/">
                <ArrowBackIcon fill="#FFFFFF" width="32px" height="32px" />
              </Link>
            </div>
            <div className="Transfer-header-title-text">Attribution / retrait de points</div>
          </div>
        </div>
        <div className="Transfer-content">
          <div className="Transfer-section">
            <div className="Transfer-section-title">Rechercher un contact</div>
            {recentContacts && !!recentContacts.length && (
                <div className="Transfer-contacts">
                  <div className="Transfer-contacts-title">Contacts récents</div>
                  <div className="Transfer-contacts-list">
                    {recentContacts.map(contact => (
                        <div key={contact.login} className="Transfer-contacts-contact" onClick={() => selectContact(contact.login)}>
                          <div className="Transfer-contacts-contact-picture">
                            <Picture {...contact} />
                          </div>
                          <div className="Transfer-contacts-contact-name">
                            {contact.firstname}
                          </div>
                        </div>
                    ))}
                  </div>
                </div>
            )}
            <div className="Transfer-contact-input">
              <input className="Transfer-input Transfer-input-contact" value={suggestionTerm} onChange={(e) => handleSuggestContacts(e.target.value)} placeholder="Entrez un nom d'utilisateur ou une adresse courriel" />
              {!!suggestions && (
                  <div className="Transfer-account-select-dropdown">
                    {!suggestions.length && (
                        <div key="no-suggestion" className="Transfer-account-select-account-info">
                          <div className="Transfer-account-select-account-info-info">
                            Pas de contact trouvé.
                          </div>
                        </div>
                    )}
                    {suggestions.map(suggestion => {
                      return (
                          <div key={suggestion.login} className="Transfer-account-select-account-info" onClick={() => selectContact(suggestion.login)}>
                            <div className="Transfer-account-select-account-info-picture">
                              <Picture {...suggestion} />
                            </div>
                            <div className="Transfer-account-select-account-info-info">
                              <div className="Transfer-account-select-account-info-balance">{suggestion.firstname} {suggestion.lastname}</div>
                              <div className="Transfer-account-select-account-info-name">{suggestion.login}{suggestion.email ? " - " + suggestion.email : ""}</div>
                            </div>
                          </div>
                      );
                    })}
                  </div>
              )}
            </div>
            {!!contact && (
                <div className="Transfer-contacts">
                  <div className="Transfer-account-select-account-info">
                    <div className="Transfer-account-select-account-info-picture Transfer-contacts-contact-picture">
                      <Picture {...contact} />
                    </div>
                    <div className="Transfer-account-select-account-info-info">
                      <div className="Transfer-account-select-account-info-balance">{contact.firstname} {contact.lastname}</div>
                      <div className="Transfer-account-select-account-info-name">{contact.login}{contact.email ? " - " + contact.email : ""}</div>
                    </div>
                  </div>
                </div>
            )}
          </div>
          {!!contact && (
              <div className="Transfer-section">
                <div className="Transfer-section-title">Grilles</div>
                {!grids && <div className="Transfer-validation-contact-email Account-hasmore"><div className="Account-hasmore-loading" /></div>}
                {grids && !grids.length && <div className="Transfer-validation-contact-email Account-hasmore">Pas de grilles de points associées.</div>}
                {grids && !!grids.length && (
                  <div>
                    <div className="">
                      {grids.map(grid => {
                        return (
                          <Link key={grid.id} to={"/grid/" + grid.id + "/" + contact.id} className="Home-account-link">
                            <div className="Home-account">
                              <div className="Home-account-picture">
                                {grid.picture && (
                                    <img src={"/img/grids/" + grid.picture} alt="" title={grid.name} />
                                )}
                                {!grid.picture && (
                                    <StarOutlineIcon fill="#108F38" height="48px" width="48px" title={grid.name} />
                                )}
                              </div>
                              <div className="Home-account-info">
                                <div className="Home-account-info-description">{grid.name}</div>
                                <div className="Home-account-info-date">{grid.description || ""}</div>
                              </div>
                              <div className={"Home-account-info-amount"}>{grid.points}</div>
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
          )}
        </div>
      </div>
  );
}

export default AttributePoints;