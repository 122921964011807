import React, { useEffect, useState } from 'react';
import formatBalance from '../../helpers/format-balance';
import { getAccountIcon, getAccountName, getShowDecimal } from '../../helpers/account-types';
import { decode } from '../../helpers/encode-decode';
import { Link, useLocation } from "react-router-dom";
import { padLeft } from '../../helpers/pad';
import Loader from "../../components/loader";
import Picture from "../../components/picture";

import './index.css';
import { getAccounts, getRecentContacts, doTransaction } from "../../api";
import { ReactComponent as ArrowBackIcon } from "../../images/icons/arrow_back_black_24dp.svg";
import { ReactComponent as ExpandMoreIcon } from "../../images/icons/expand_more_black_24dp.svg";
import { ReactComponent as TaskAltIcon } from "../../images/icons/task_alt_black_24dp.svg";
import { ReactComponent as QRCodeScannerIcon } from "../../images/icons/qr_code_scanner_black_24dp.svg";
import { ReactComponent as CancelIcon } from "../../images/icons/cancel_black_24dp.svg";
import { ReactComponent as CameraSwitchIcon } from "../../images/icons/cameraswitch_black_24dp.svg";
import formatDate from "../../helpers/format-date";

const ERROR_MESSAGES = {
  "INVALID_INPUT": "Données invalides.",
  "SOURCE_NOT_FOUND": "Le compte source n'a pas été trouvé.",
  "DESTINATION_NOT_FOUND": "Le compte de destination n'a pas été trouvé.",
  "NOT_SAME_CURRENCY": "Les comptes n'utilisent pas la même devise.",
  "INVALID_AMOUNT": "Montant invalide.",
  "INSUFFICIENT_FUNDS": "Pas assez de fonds.",
  "DIFFERENT_SOURCE_DESTINATION": "La source et la destination doivent être différents.",
  "INSERT_ERROR": "Erreur inconnue.",
  "INVALID_QR": "Code QR invalide.",
};

function Transfer() {
  const [accounts, setAccounts] = useState(null);
  const [recentContacts, setRecentContacts] = useState(null);

  const location = useLocation();
  const fromAccount = location.state && location.state.fromAccount;
  const [selectedAccount, setSelectedAccount] = useState(fromAccount);
  const [selectedContact, setSelectedContact] = useState("");
  const [selectedAmount, setSelectedAmount] = useState(null);
  const [selectedAmountString, setSelectedAmountString] = useState("");
  const [selectedDescription, setSelectedDescription] = useState("");
  const [validationScreen, setValidationScreen] = useState(false);
  const [doingTransaction, setDoingTransaction] = useState(false);
  const [transactionError, setTransactionError] = useState(null);
  const [transactionResult, setTransactionResult] = useState(null);
  const [scanning, setScanning] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [inputFocused, setInputFocused] = useState(false);

  useEffect(() => {
    Promise.all([
      getAccounts().then(accounts => () => setAccounts(accounts)),
      getRecentContacts().then(contacts => () => setRecentContacts(contacts)),
      new Promise(accept => setTimeout(() => accept(() => {}), 1000)),
    ]).then(cbs => cbs.forEach(cb => cb()));
  }, []);

  const [accountSelectionOpened, setAccountSelectionOpened] = useState(false);
  const closeAccountSelection = () => {
    document.body.removeEventListener("click", closeAccountSelection);
    setAccountSelectionOpened(false);
  }
  const openAccountSelection = () => {
    if(!accountSelectionOpened) {
      setTimeout(() => document.body.addEventListener("click", closeAccountSelection), 0);
      setAccountSelectionOpened(true);
    }
  }

  const [targetAccountSelectionOpened, setTargetAccountSelectionOpened] = useState(false);
  const closeTargetAccountSelection = () => {
    document.body.removeEventListener("click", closeTargetAccountSelection);
    setTargetAccountSelectionOpened(false);
  }
  const openTargetAccountSelection = () => {
    if(!targetAccountSelectionOpened) {
      setTimeout(() => document.body.addEventListener("click", closeTargetAccountSelection), 0);
      setTargetAccountSelectionOpened(true);
    }
  }

  if(!accounts || !recentContacts) {
    return (
      <div className="Transfer">
        <Loader />
      </div>
    );
  }

  const account = accounts.find(a => a.id === selectedAccount);
  const AccountIcon = getAccountIcon(account && account.type);
  const selectedAmountFormatted = selectedAmount === null ? "" : (account ? formatBalance(selectedAmount, getShowDecimal(account.type), account.currency_symbol) : "" + formatBalance(selectedAmount, false, ""));

  const setAmount = (e) => setSelectedAmountString(("" + e.target.value).replace(/[^0-9.,]/g, ""));
  const onFocus = () => {
    setSelectedAmountString(selectedAmount === null ? "" : ("" + (selectedAmount / 100)));
    setInputFocused(true);
  }
  const onBlur = () => {
    let value = parseFloat(selectedAmountString.replace(/,/g, "."));

    if(isNaN(value)) {
      setSelectedAmount(null);
      return;
    }

    value = Math.floor(value * 100);

    // Remove decimal part if not allowed
    if(!getShowDecimal(account && account.type)) {
      value = Math.floor(value / 100) * 100;
    }

    // Amount must be greater than 0
    if(value < 0) {
      return;
    }

    // Amount can't exceed balance
    if(account) {
      value = Math.min(value, account.balance);
    }

    setSelectedAmount(value);
    setInputFocused(false);
  }

  const sameCurrencyAccounts = Object.values(accounts.reduce((res, a) => ({ ...res, [a.currency]: [...(res[a.currency] || []), a] }), {})).filter(accounts => accounts.length > 1);
  const selectedCurrencyTargetAccounts = selectedAccount ? (sameCurrencyAccounts.find(t => !!t.find(a => a.id === selectedAccount)) || []).filter(a => a.id !== selectedAccount) : [];
  const selectedDestinationAccount = selectedCurrencyTargetAccounts.find(a => a.id === selectedContact);
  const DestinationAccountIcon = getAccountIcon(selectedDestinationAccount && selectedDestinationAccount.type);
  const changeTab = (tab) => {
    setSelectedTab(tab);
    setSelectedContact("");
    if(selectedAccount && tab === 1) {
      if(!sameCurrencyAccounts.flat().find(a => a.id === selectedAccount)) {
        setSelectedAccount(null);
      }
    }
  }

  // Confirmation page
  if(transactionResult) {
    return (
      <div className="Transfer">
        <div className="Transfer-header">
          <div className="Transfer-header-title">
            <Link to={fromAccount ? "/account/" + fromAccount : "/"}>
              <ArrowBackIcon fill="#FFFFFF" width="32px" height="32px" />
            </Link>
            <div className="Transfer-header-title-text">Transaction effectuée</div>
          </div>
          <div className="Transfer-confirmation">
            <div className="Transfer-confirmation-logo">
              <TaskAltIcon fill="#FFFFFF" width="128px" height="128px" />
            </div>
            <div className="Transfer-confirmation-result">Transfert effectué.</div>
            {!!transactionResult.code && (
              <div className="Transfer-confirmation-text">Votre code de validation est le {transactionResult.code}.</div>
            )}
          </div>
          <Link className="Account-header-action-link" to={fromAccount ? "/account/" + fromAccount : "/"}>
            <div className={"Transfer-button"}>Retour</div>
          </Link>
        </div>
      </div>
    );
  }

  // Validation page
  if(validationScreen) {
    const contact = recentContacts.find(c => c.login === selectedContact || c.email === selectedContact);
    const sendTransaction = () => {
      setDoingTransaction(true);
      Promise.all([
        doTransaction(selectedAccount, selectedContact, selectedAmount, selectedDescription).catch(error => ({ error })),
        new Promise(accept => setTimeout(() => accept(() => {}), 1500)),
      ]).then(([result]) => {
        setDoingTransaction(false);
        if(result && result.success) {
          setTransactionResult(result);
        } else if (result && result.error) {
          setTransactionError(result.error);
        }
      });
    };

    return (
      <div className="Transfer">
        <div className="Transfer-header">
          <div className="Transfer-header-title">
            <div className="Transfer-header-title-icon" onClick={() => setValidationScreen(false)}>
              <ArrowBackIcon fill="#FFFFFF" width="32px" height="32px" />
            </div>
            <div className="Transfer-header-title-text">Confirmation du virement</div>
          </div>
          <div className="Transfer-validation">
            {!contact && !selectedDestinationAccount && (
              <div className="Transfer-validation-contact">
                {selectedContact}
              </div>
            )}
            {!contact && !!selectedDestinationAccount && (
              <div className="Transfer-validation-from">
                <div className="Transfer-account-select-account-info">
                  <div className="Transfer-account-select-account-info-picture">
                    <DestinationAccountIcon fill="#FFFFFF" height="48px" width="48px" />
                  </div>
                  <div className="Transfer-account-select-account-info-info">
                    <div className="Transfer-account-select-account-info-name">{getAccountName(selectedDestinationAccount.type)} (n°{padLeft(selectedDestinationAccount.id, 8)})</div>
                    <div className="Transfer-account-select-account-info-balance">{formatBalance(selectedDestinationAccount.balance, getShowDecimal(selectedDestinationAccount.type), selectedDestinationAccount.currency_symbol)}</div>
                  </div>
                </div>
              </div>
            )}
            {!!contact && (
              <div className="Transfer-validation-contact">
                <div className="Transfer-validation-contact-picture">
                  <Picture {...contact} />
                </div>
                <div className="Transfer-validation-contact-name">
                  {contact.firstname} {contact.lastname}
                </div>
                {!!contact.email && (
                  <div className="Transfer-validation-contact-email">{contact.email}</div>
                )}
              </div>
            )}
            <div className="Transfer-validation-date">Transfert le {formatDate(new Date(), "%-d %b %Y")}</div>
            <div className="Transfer-validation-amount">{selectedAmountFormatted}</div>
            {!!selectedDescription && (
              <div className="Transfer-validation-description">{selectedDescription}</div>
            )}
            <div className="Transfer-validation-from">
              <div>Depuis :</div>
              <div className="Transfer-account-select-account-info">
                <div className="Transfer-account-select-account-info-picture">
                  <AccountIcon fill="#FFFFFF" height="48px" width="48px" />
                </div>
                <div className="Transfer-account-select-account-info-info">
                  <div className="Transfer-account-select-account-info-name">{getAccountName(account.type)} (n°{padLeft(account.id, 8)})</div>
                  <div className="Transfer-account-select-account-info-balance">{formatBalance(account.balance, getShowDecimal(account.type), account.currency_symbol)}</div>
                </div>
              </div>
            </div>
          </div>
          {!!transactionError && (
            <div className="Transfer-validation-error">
              {(transactionError.code && ERROR_MESSAGES[transactionError.code]) || transactionError.error || "Erreur inconnue."}
            </div>
          )}
          <div className={"Transfer-button"} onClick={sendTransaction}>Envoyer</div>
        </div>
        {doingTransaction && (
          <div className="Transfer-loader">
            <Loader text={"Transfert en cours..."} goingOut />
          </div>
        )}
      </div>
    );
  }

  const detectCameras = () => {
    window.Html5Qrcode.getCameras().then(cameras => {
      if (cameras && cameras.length) {
        const devices = [];
        cameras.forEach(d => {
          if(d.label.includes(",")) {
            const name = d.label.replace(/.*,/, "");
            if(devices.find(d2 => d2.id !== d.id && d2.label.includes(name))) {
              return;
            }
          }
          devices.push(d);
        });
        const cameraId = devices[0].id;

        setScanning({ cameraId, devices });
      }
    }).catch(_ => {
      // handle err
    });
  };

  const processQR = html5QrCode => qrCodeMessage => {
    try {
      const contactData = JSON.parse(atob(decode(qrCodeMessage)));
      html5QrCode.stop();
      setScanning({ contactData: {
        n: contactData.n,
        o: contactData.o,
        firstname: contactData.f,
        lastname: contactData.l,
        email: contactData.e,
        login: contactData.u,
        picture: contactData.p,
      }});
    } catch(e) {
      setScanning(state => ({ ...state, error: "INVALID_QR" }));
      setTimeout(() => setScanning(state => ({ ...state, error: undefined })), 2500);
    }
  };

  const switchCamera = () => {
    if(scanning && scanning.html5QrCode) {
      scanning.html5QrCode.stop().then(_ => {
        const deviceIndex = scanning.devices.findIndex(d => d.id === scanning.cameraId);
        const cameraId = scanning.devices[(deviceIndex + 1)%scanning.devices.length].id;
        setScanning(state => ({ ...state, cameraId }));
        scanning.html5QrCode.start(
          cameraId,
          { fps: 10, qrbox: 250 },
          processQR(scanning.html5QrCode),
          _ => {}
        ).catch(err => {
          // Start failed, handle it. For example,
          console.log(`Unable to start scanning, error: ${err}`);
        });
      });
    }
  }

  const startScanning = (e) => {
    if(e && !scanning.html5QrCode) {
      const html5QrCode = new window.Html5Qrcode("reader");
      html5QrCode.start(
        scanning.cameraId,
        { fps: 10, qrbox: 250 },
        processQR(html5QrCode),
        _ => {}
      ).catch(err => {
        // Start failed, handle it. For example,
        console.log(`Unable to start scanning, error: ${err}`);
      });
      setScanning(state => ({ ...state, html5QrCode }));
    }
  }

  const stopScanning = () => {
    if(scanning && scanning.html5QrCode) {
      scanning.html5QrCode.stop();
    }
    setScanning(null);
  }

  // Form page
  return (
    <div className="Transfer">
      <div className="Transfer-header">
        <div className="Transfer-header-title">
          <div className="Transfer-header-title-icon">
            <Link to={fromAccount ? "/account/" + fromAccount : "/"}>
              <ArrowBackIcon fill="#FFFFFF" width="32px" height="32px" />
            </Link>
          </div>
          <div className="Transfer-header-title-text">Virement</div>
        </div>
      </div>
      <div className="Transfer-content">
        {!!sameCurrencyAccounts.length && (
          <div className="Transfer-tabs">
            <div className={"Transfer-tabs-tab" + (selectedTab === 0 ? " Transfer-tabs-tab-selected" : "")} onClick={() => changeTab(0)}>À un contact</div>
            <div className={"Transfer-tabs-tab" + (selectedTab === 1 ? " Transfer-tabs-tab-selected" : "")} onClick={() => changeTab(1)}>Entre comptes</div>
          </div>
        )}
        <div className="Transfer-section">
          <div className="Transfer-section-title">Compte d'origine</div>
          <div className={"Transfer-account-select " + (accountSelectionOpened ? "Transfer-account-select-opened" : "Transfer-account-select-closed")}>
            <div className="Transfer-account-select-selected" onClick={openAccountSelection}>
              {!account && (
                <div className="Transfer-account-select-selected-placeholder">Veuillez sélectionner un compte</div>
              )}
              {!!account && (
                <div className="Transfer-account-select-account-info">
                  <div className="Transfer-account-select-account-info-picture">
                    <AccountIcon fill="#108F38" height="48px" width="48px" />
                  </div>
                  <div className="Transfer-account-select-account-info-info">
                    <div className="Transfer-account-select-account-info-name">{getAccountName(account.type)} (n°{padLeft(account.id, 8)})</div>
                    <div className="Transfer-account-select-account-info-balance">{formatBalance(account.balance, getShowDecimal(account.type), account.currency_symbol)}</div>
                  </div>
                </div>
              )}
              <div className="Transfer-account-select-selected-arrow">
                <ExpandMoreIcon />
              </div>
            </div>
            {accountSelectionOpened && (
              <div className="Transfer-account-select-dropdown">
                {(selectedTab === 0 ? accounts : sameCurrencyAccounts.flat()).map(account => {
                  const AccountIcon = getAccountIcon(account.type);
                  return (
                    <div key={account.id} className="Transfer-account-select-account-info" onClick={() => { if(account.id !== selectedAccount) { setSelectedAccount(account.id); if(!account || !selectedAccount || account.currency !== accounts.find(a => a.id === selectedAccount).currency) { setSelectedAmount(null); } if(selectedTab === 1) { setSelectedContact(""); } } }}>
                      <div className="Transfer-account-select-account-info-picture">
                        <AccountIcon fill="#108F38" height="48px" width="48px" />
                      </div>
                      <div className="Transfer-account-select-account-info-info">
                        <div className="Transfer-account-select-account-info-name">{getAccountName(account.type)} (n°{padLeft(account.id, 8)})</div>
                        <div className="Transfer-account-select-account-info-balance">{formatBalance(account.balance, getShowDecimal(account.type), account.currency_symbol)}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {selectedTab === 0 && (
          <div className="Transfer-section">
            <div className="Transfer-section-title">Contact</div>
            <div className="Transfer-contact-input">
              <input className="Transfer-input Transfer-input-contact" value={selectedContact} onChange={(e) => setSelectedContact(e.target.value)} placeholder="Entrez un nom d'utilisateur ou une adresse courriel" />
              <QRCodeScannerIcon className="Transfer-qrcode-button" onClick={detectCameras}/>
            </div>
            {!!recentContacts.length && (
              <div className="Transfer-contacts">
                <div className="Transfer-contacts-title">Contacts récents</div>
                <div className="Transfer-contacts-list">
                  {recentContacts.map(contact => (
                    <div key={contact.login} className="Transfer-contacts-contact" onClick={() => setSelectedContact(contact.login)}>
                      <div className="Transfer-contacts-contact-picture">
                        <Picture {...contact} />
                      </div>
                      <div className="Transfer-contacts-contact-name">
                        {contact.firstname}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        {selectedTab === 1 && (
          <div className="Transfer-section">
            <div className="Transfer-section-title">Compte de destination</div>
            <div className={"Transfer-account-select " + (targetAccountSelectionOpened ? "Transfer-account-select-opened" : "Transfer-account-select-closed")}>
              <div className="Transfer-account-select-selected" onClick={account ? openTargetAccountSelection : undefined}>
                {!account && (
                  <div className="Transfer-account-select-selected-placeholder">Veuillez sélectionner un compte source</div>
                )}
                {!!account && !selectedDestinationAccount && (
                  <div className="Transfer-account-select-selected-placeholder">Veuillez sélectionner un compte</div>
                )}
                {!!account && !!selectedDestinationAccount && (
                  <div className="Transfer-account-select-account-info">
                    <div className="Transfer-account-select-account-info-picture">
                      <DestinationAccountIcon fill="#108F38" height="48px" width="48px" />
                    </div>
                    <div className="Transfer-account-select-account-info-info">
                      <div className="Transfer-account-select-account-info-name">{getAccountName(selectedDestinationAccount.type)} (n°{padLeft(selectedDestinationAccount.id, 8)})</div>
                      <div className="Transfer-account-select-account-info-balance">{formatBalance(selectedDestinationAccount.balance, getShowDecimal(selectedDestinationAccount.type), selectedDestinationAccount.currency_symbol)}</div>
                    </div>
                  </div>
                )}
                <div className="Transfer-account-select-selected-arrow">
                  <ExpandMoreIcon />
                </div>
              </div>
              {targetAccountSelectionOpened && (
                <div className="Transfer-account-select-dropdown">
                  {selectedCurrencyTargetAccounts.map(account => {
                    const AccountIcon = getAccountIcon(account.type);
                    return (
                      <div key={account.id} className="Transfer-account-select-account-info" onClick={() => setSelectedContact(account.id)}>
                        <div className="Transfer-account-select-account-info-picture">
                          <AccountIcon fill="#108F38" height="48px" width="48px" />
                        </div>
                        <div className="Transfer-account-select-account-info-info">
                          <div className="Transfer-account-select-account-info-name">{getAccountName(account.type)} (n°{padLeft(account.id, 8)})</div>
                          <div className="Transfer-account-select-account-info-balance">{formatBalance(account.balance, getShowDecimal(account.type), account.currency_symbol)}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
        <div className="Transfer-section">
          <div className="Transfer-section-title">Montant</div>
          <div className="Transfer-amount-input">
            <input
              className="Transfer-input Transfer-input-amount"
              value={inputFocused ? selectedAmountString : selectedAmountFormatted}
              onChange={setAmount}
              onFocus={onFocus}
              onBlur={onBlur}
              type="text"
              inputMode={getShowDecimal(account && account.type) ? "decimal" : "numeric"}
              placeholder="Entrez un montant à transférer"
            />
          </div>
          {/* TODO : Recent amounts for this account, as semi-chips underneath (template 1) */}
        </div>
        <div className="Transfer-section">
          <div className="Transfer-section-title">Description</div>
          <div>
            <input className="Transfer-input" value={selectedDescription} onChange={(e) => setSelectedDescription(e.target.value)} placeholder="Entrez une description (facultatif)" maxLength="50" />
          </div>
        </div>
        <div className={"Transfer-button" + (!account || !selectedContact || selectedAmount === 0 ? " Transfer-button-disabled" : "")} onClick={() => setValidationScreen(true)}>Valider</div>
      </div>
      {scanning && (
        <div className="Transfer-scanning">
          {!scanning.contactData && (
            <div className="Transfer-scanning-video" id="reader" ref={startScanning} />
          )}
          {!!scanning.contactData && (
            <div className="Transfer-scanning-contact">
              <div className="Transfer-scanning-contact-picture">
                <Picture {...scanning.contactData} />
              </div>
              <div className="Transfer-scanning-contact-name">
                {scanning.contactData.firstname} {scanning.contactData.lastname}
              </div>
              {!!scanning.contactData.email && (
                <div className="Transfer-scanning-contact-email">{scanning.contactData.email}</div>
              )}
              <div className="Transfer-scanning-contact-account">Compte n°{padLeft(scanning.contactData.n, 8)}</div>
            </div>
          )}
          <div className="Transfer-scanning-cancel-buttons">
            {scanning.devices && scanning.devices.length > 1 && (
              <CameraSwitchIcon fill="#FFFFFF" width="32px" height="32px" onClick={switchCamera} />
            )}
            <div style={{ flex: 1 }} />
            <CancelIcon fill="#FFFFFF" width="32px" height="32px" onClick={stopScanning} />
          </div>
          <div className={"Transfer-scanning-validation-buttons" + (scanning.contactData ? "" : " Transfer-scanning-validation-button-disabled")}>
            <div className="Transfer-scanning-button" onClick={() => { setSelectedContact(scanning.contactData.o ? scanning.contactData.n : scanning.contactData.login || scanning.contactData.email); stopScanning(); }}>Valider</div>
          </div>
          <div className={"Transfer-scanning-error" + (scanning.error ? " Transfer-scanning-error-visible" : "")}>
            <div className="Transfer-scanning-error-content">
              {ERROR_MESSAGES[scanning.error] || scanning.error}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Transfer;