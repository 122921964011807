import { ReactComponent as GameIcon } from "../images/icons/sports_esports_black_24dp.svg";
import { ReactComponent as TvIcon } from "../images/icons/live_tv_black_24dp.svg";
import { ReactComponent as PeopleAltIcon } from "../images/icons/people_alt_black_24dp.svg";
import { ReactComponent as HelpIcon } from "../images/icons/help_outline_black_24dp.svg";
import { ReactComponent as CurrencyExchangeIcon } from "../images/icons/currency_exchange_black_24dp.svg";
import { ReactComponent as AttachMoneyIcon } from "../images/icons/attach_money_black_24dp.svg";
import { ReactComponent as TheatersIcon } from "../images/icons/theaters_black_24dp.svg";

export const icons = {
  "videogame": GameIcon,
  "tv": TvIcon,
  "friends": PeopleAltIcon,
  "unknown": HelpIcon,
  "conversion": CurrencyExchangeIcon,
  "dollars": AttachMoneyIcon,
  "cine": TheatersIcon,
};

export function getIcon(code) {
  return (code && icons[code]) || HelpIcon;
}