// %%     a literal %
// %a     locale's abbreviated weekday name (e.g., Sun)
// %A     locale's full weekday name (e.g., Sunday)
// %b     locale's abbreviated month name (e.g., Jan)
// %B     locale's full month name (e.g., January)
// %d     day of month (e.g., 01)
// %H     hour (00..23)
// %I     hour (01..12)
// %m     month (01..12)
// %M     minute (00..59)
// %p     locale's equivalent of either AM or PM; blank if not known
// %P     like %p, but lower case
// %S     second (00..60)
// %u     day of week (1..7); 1 is Monday
// %w     day of week (0..6); 0 is Sunday
// %y     last two digits of year (00..99)
// %Y     year
// %z     +hhmm numeric time zone (e.g., -0400)
// %:z    +hh:mm numeric time zone (e.g., -04:00)
// -      (hyphen) do not pad the field
// _      (underscore) pad with spaces
// #      use lower case if possible

import { padLeft } from "./pad";

const DAYS_SHORT = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"];
const DAYS_LONG = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
const MONTHS_SHORT = ["Jan.", "Fév.", "Mars", "Avr.", "Mai", "Juin", "Jui.", "Août", "Sep.", "Oct.", "Nov.", "Déc."];
const MONTHS_LONG = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
const AM = "AM";
const PM = "PM";

export default function formatDate(date, format = "%-d %b %Y · %H:%I") {
  return format.replace(/%([#:_-])?([%aAbBdHImMpPSuwyYz])/g, (m, modifier, letter) => {
    let padLength = 2;
    let padChar = "0";
    let lower = x => x;
    if(modifier === "-") {
      padLength = 0;
    } else if(modifier === "_") {
      padChar = " ";
    } else if(modifier === "#") {
      lower = x => ("" + x).toLowerCase();
    }
    if(letter === "%") {
      return "%"
    } else if(letter === "a") {
      return lower(DAYS_SHORT[date.getDay()]);
    } else if(letter === "A") {
      return lower(DAYS_LONG[date.getDay()]);
    } else if(letter === "b") {
      return lower(MONTHS_SHORT[date.getMonth()]);
    } else if(letter === "B") {
      return lower(MONTHS_LONG[date.getMonth()]);
    } else if(letter === "d") {
      return padLeft(date.getDate(), padLength, padChar);
    } else if(letter === "H") {
      return padLeft(date.getHours(), padLength, padChar);
    } else if(letter === "I") {
      let hour = date.getHours() % 12;
      if(hour === 0) {
        hour = 12;
      }
      return padLeft(hour, padLength, padChar);
    } else if(letter === "m") {
      return padLeft(date.getMonth() + 1, padLength, padChar);
    } else if(letter === "M") {
      return padLeft(date.getMinutes(), padLength, padChar);
    } else if(letter === "p") {
      if(date.getHours() < 12) {
        return lower(AM);
      }
      return lower(PM);
    } else if(letter === "P") {
      if(date.getHours() < 12) {
        return AM.toLowerCase();
      }
      return PM.toLowerCase();
    } else if(letter === "S") {
      return padLeft(date.getSeconds(), padLength, padChar);
    } else if(letter === "u") {
      let day = date.getDay();
      if(day === 0) {
        day = 7;
      }
      return padLeft(day, padLength, padChar);
    } else if(letter === "w") {
      return padLeft(date.getDay(), padLength, padChar);
    } else if(letter === "y") {
      return padLeft(date.getFullYear() - Math.floor(date.getFullYear() / 100) * 100, padLength, padChar);
    } else if(letter === "Y") {
      return date.getFullYear();
    } else if(letter === "z") {
      const offset = date.getTimezoneOffset();
      const hours = Math.floor(offset / 60);
      const minutes = offset - hours * 60;
      if(modifier === ":") {
        return padLeft(hours, padLength, padChar) + ":" + padLeft(minutes);
      }
      return padLeft(hours, padLength, padChar) + padLeft(minutes);
    }
    return m;
  });
};