import React from 'react';

import './index.css';

const hashCode = (str) => {
  var hash = 0, i, chr, len;
  if (str.length === 0) return hash;
  for (i = 0, len = str.length; i < len; i++) {
    chr   = str.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

function Picture(props) {
  let color = 0;
  if(props.login) {
    color = hashCode(props.login) % 5;
  }

  if(props.picture) {
    return (
        <div className={"Picture-thumbnail Picture-color-" + color} style={{ backgroundImage: "url(/img/" + props.picture + ".png)" }} />
    );
  }

  let initials = "";
  if(props.firstname) {
    initials += props.firstname.substring(0, 1).toUpperCase();
  }
  if(props.lastname) {
    initials += props.lastname.substring(0, 1).toUpperCase();
  }
  if(!initials && props.login) {
    initials += props.login.substring(0, 1).toUpperCase();
  }
  return (
    <div className={"Picture-thumbnail Picture-color-" + color}>{initials}</div>
  );
}

export default Picture;