import React, { useState, useEffect } from 'react';
import { getUser, getAccounts, getLatestTransactions, getLatestRewards, logout, getGridsSummary } from "../../api";
import formatBalance from '../../helpers/format-balance';
import formatDate from '../../helpers/format-date';
import { getAccountIcon, getAccountName, getShowDecimal } from '../../helpers/account-types';
import { getCategoryIcon, getCategoryName, getCategoryColor } from '../../helpers/categories';
import { padLeft } from '../../helpers/pad';
import { Link } from "react-router-dom";

import './index.css';
import { ReactComponent as PaidIcon } from "../../images/icons/paid_black_24dp.svg";
import { ReactComponent as SendIcon } from "../../images/icons/send_black_24dp.svg";
import { ReactComponent as PersonIcon } from "../../images/icons/person_black_24dp.svg";
import { ReactComponent as SwapHorizIcon } from "../../images/icons/swap_horiz_black_24dp.svg";
import { ReactComponent as HomeIcon } from "../../images/icons/home_black_24dp.svg";
import { ReactComponent as LogoutIcon } from "../../images/icons/logout_black_24dp.svg";
import { ReactComponent as SettingsIcon } from "../../images/icons/settings_black_24dp.svg";
import { ReactComponent as FormatListBulletedIcon } from "../../images/icons/format_list_bulleted_black_24dp.svg";
import { ReactComponent as RedeemIcon } from "../../images/icons/redeem_black_24dp.svg";
import { ReactComponent as StarIcon } from "../../images/icons/star_black_24dp.svg";
import { ReactComponent as StarOutlineIcon } from "../../images/icons/star_outline_black_24dp.svg";
import chevronRightIcon from "../../images/icons/chevron_right_black_24dp.svg";
import Loader from "../../components/loader";
import Picture from "../../components/picture";
import { getIcon } from "../../helpers/icons";

function Home() {
  const [user, setUser] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [latestTransactions, setLatestTransactions] = useState(null);
  const [latestRewards, setLatestRewards] = useState(null);
  const [gridsSummary, setGridsSummary] = useState(null);
  const [accountsExpanded, setAccountsExpanded] = useState(true);
  const [transactionsExpanded, setTransactionsExpanded] = useState(false);
  const [rewardsExpanded, setRewardsExpanded] = useState(false);
  const [gridsExpanded, setGridsExpanded] = useState(false);
  const [administrationExpanded, setAdministrationExpanded] = useState(false);

  useEffect(() => {
    Promise.all([
      getUser().then(user => () => setUser(user)),
      getAccounts().then(accounts => () => setAccounts(accounts || [])),
      getLatestTransactions().then(transactions => () => setLatestTransactions(transactions || [])),
      getLatestRewards().then(rewards => () => setLatestRewards(rewards || [])),
      getGridsSummary().then(grids => () => setGridsSummary(grids || [])),
      new Promise(accept => setTimeout(() => accept(() => {}), 1000)),
    ]).then(cbs => cbs.forEach(cb => cb()));
  }, []);

  if(!user || !accounts || !latestTransactions || !latestRewards) {
    return (
      <div className="Home">
        <Loader />
      </div>
    );
  }

  return (
    <div className="Home">
      <div className="Home-header">
        <div className="Home-header-title">
          <div className="Home-header-title-icon">
            <Link to="/">
              <HomeIcon fill="#FFFFFF" width="32px" height="32px" />
            </Link>
          </div>
          <div className="Home-header-title-text">Mon Profil</div>
          <div className="Home-header-title-action" onClick={logout}>
            <LogoutIcon fill="#FFFFFF" width="32px" height="32px" title="Se déconnecter" />
          </div>
        </div>
        <div className="Home-header-contact">
          <div className="Home-header-contact-picture">
            <Picture {...user} />
          </div>
          <div className="Home-header-contact-info">
            <div className="Home-header-contact-info-name">{user.firstname} {user.lastname}</div>
            <div className="Home-header-contact-info-email">{user.email}</div>
          </div>
        </div>
        <div className="Home-header-account-balance-cardbg-2" />
        <div className="Home-header-account-balance-cardbg-1" />
        <div className="Home-header-account-balance">
          <div className="Home-header-account-balance-text">Balance</div>
          <div className="Home-header-account-balance-amount">{formatBalance(accounts.filter(acc => acc.currency === accounts[0].currency).reduce((total, acc) => total + parseInt(acc.balance, 10), 0), getShowDecimal(accounts[0].type), accounts[0].currency_symbol)}</div>
        </div>
      </div>
      <div className="Home-content">
        <div className={"Home-content-card" + (accountsExpanded ? " Home-content-card-expanded" : "")}>
          <div className="Home-content-button" onClick={() => setAccountsExpanded(!accountsExpanded)}>
            <div className="Home-content-button-icon">
              <PaidIcon fill="#108F38" />
            </div>
            <div className="Home-content-button-text">
              Mes comptes
            </div>
            <div className="Home-content-button-trailing">
              <img src={chevronRightIcon} alt="" />
            </div>
          </div>
          <div className="Home-content-card-content">
            {!accounts.length && (
              <div>Aucun compte relié.</div>
            )}
            {accounts.map(account => {
              const AccountIcon = getAccountIcon(account.type);
              return (
                <Link key={account.id} to={"/account/" + account.id} className="Home-account-link">
                  <div key={account.id} className="Home-account">
                    <div className="Home-account-picture">
                      <AccountIcon fill="#108F38" height="48px" width="48px" />
                    </div>
                    <div className="Home-account-info">
                      <div className="Home-account-info-name">{getAccountName(account.type)} (n°{padLeft(account.id, 8)})</div>
                      <div className="Home-account-info-balance">{formatBalance(account.balance, getShowDecimal(account.type), account.currency_symbol)}</div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
        <div className={"Home-content-card" + (transactionsExpanded ? " Home-content-card-expanded" : "")}>
          <div className="Home-content-button" onClick={() => setTransactionsExpanded(!transactionsExpanded)}>
            <div className="Home-content-button-icon">
              <SwapHorizIcon fill="#108F38" />
            </div>
            <div className="Home-content-button-text">
              Dernières transactions
            </div>
            <div className="Home-content-button-trailing">
              <img src={chevronRightIcon} alt="" />
            </div>
          </div>
          <div className="Home-content-card-content">
            {!latestTransactions.length && (
              <div>Aucune transaction.</div>
            )}
            {latestTransactions.map(transaction => {
              const account = accounts.find(a => a.id === transaction.account) || {};
              const TransactionIcon = getCategoryIcon(transaction.category);
              const debit = transaction.account === transaction.source;
              const date = new Date(transaction.date * 1000);
              return (
                <Link key={transaction.id + "-" + transaction.account} to={"/account/" + transaction.account} className="Home-account-link">
                  <div className="Home-account">
                    <div className="Home-account-picture">
                      <TransactionIcon fill={getCategoryColor(transaction.category)} height="48px" width="48px" title={getCategoryName(transaction.category)} />
                    </div>
                    <div className="Home-account-info">
                      <div className="Home-account-info-description">{transaction.description || ("Transaction " + transaction.code)}</div>
                      <div className="Home-account-info-date">{formatDate(date)}</div>
                    </div>
                    <div className={"Home-account-info-amount " + (debit ? "Home-account-info-amount-debit" : "Home-account-info-amount-credit")}>{debit ? "-" : "+"}{formatBalance(transaction.amount, getShowDecimal(account.type), account.currency_symbol)}</div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
        <div className={"Home-content-card" + (rewardsExpanded ? " Home-content-card-expanded" : "")}>
          <div className="Home-content-button" onClick={() => setRewardsExpanded(!rewardsExpanded)}>
            <div className="Home-content-button-icon">
              <RedeemIcon fill="#108F38" />
            </div>
            <div className="Home-content-button-text">
              Mes récompenses
            </div>
            <div className="Home-content-button-trailing">
              <img src={chevronRightIcon} alt="" />
            </div>
          </div>
          <div className="Home-content-card-content">
            {!latestRewards.length && (
              <div>Aucune récompense.</div>
            )}
            {latestRewards.map(reward => {
              const account = accounts.find(a => a.id === reward.account) || {};
              const RewardIcon = getIcon(reward.icon);
              const date = new Date(reward.date * 1000);
              return (
                <Link key={reward.id + "-" + reward.account} to={"/account/" + reward.account} className="Home-account-link">
                  <div className="Home-account">
                    <div className="Home-account-picture">
                      {reward.picture && (
                        <img src={"/img/rewards/" + reward.picture} alt="" title={reward.name} />
                      )}
                      {!reward.picture && (
                        <RewardIcon fill="#108F38" height="48px" width="48px" title={reward.name} />
                      )}
                    </div>
                    <div className="Home-account-info">
                      <div className="Home-account-info-description">{reward.name}</div>
                      <div className="Home-account-info-date">{formatDate(date)}</div>
                    </div>
                    <div className={"Home-account-info-amount"}>{formatBalance(reward.cost, getShowDecimal(account.type), account.currency_symbol)}</div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
        {gridsSummary && !!gridsSummary.length && (
          <div className={"Home-content-card" + (gridsExpanded ? " Home-content-card-expanded" : "")}>
            <div className="Home-content-button" onClick={() => setGridsExpanded(!gridsExpanded)}>
              <div className="Home-content-button-icon">
                <StarIcon fill="#108F38" />
              </div>
              <div className="Home-content-button-text">
                Mes points
              </div>
              <div className="Home-content-button-trailing">
                <img src={chevronRightIcon} alt="" />
              </div>
            </div>
            <div className="Home-content-card-content">
              {!gridsSummary.length && (
                  <div>Aucune grille de point activée pour ce compte.</div>
              )}
              {gridsSummary.map(grid => {
                return (
                    <Link key={grid.id} to={"/grid/" + grid.id} className="Home-account-link">
                      <div className="Home-account">
                        <div className="Home-account-picture">
                          {grid.picture && (
                              <img src={"/img/grids/" + grid.picture} alt="" title={grid.name} />
                          )}
                          {!grid.picture && (
                              <StarOutlineIcon fill="#108F38" height="48px" width="48px" title={grid.name} />
                          )}
                        </div>
                        <div className="Home-account-info">
                          <div className="Home-account-info-description">{grid.name}</div>
                          <div className="Home-account-info-date">{grid.description || ""}</div>
                        </div>
                        <div className={"Home-account-info-amount"}>{grid.points}</div>
                      </div>
                    </Link>
                );
              })}
            </div>
          </div>
        )}
        <Link to={"/transfer"} className="Home-content-button-link">
          <div className="Home-content-button">
            <div className="Home-content-button-icon">
              <SendIcon fill="#108F38" />
            </div>
            <div className="Home-content-button-text">
              Effectuer un virement
            </div>
            <div className="Home-content-button-trailing">
              <img src={chevronRightIcon} alt="" />
            </div>
          </div>
        </Link>
        <Link to={"/profile"} className="Home-content-button-link">
          <div className="Home-content-button">
            <div className="Home-content-button-icon">
              <PersonIcon fill="#108F38" />
            </div>
            <div className="Home-content-button-text">
              Mon profil
            </div>
            <div className="Home-content-button-trailing">
              <img src={chevronRightIcon} alt="" />
            </div>
          </div>
        </Link>
        {/* TODO : Add administration tasks
            - Add/Set/Delete filters to automatically categorize transactions
            - Create an account ?
        */}
        {user.rights > 0 && (
          <div className={"Home-content-card" + (administrationExpanded ? " Home-content-card-expanded" : "")}>
            <div className="Home-content-button" onClick={() => setAdministrationExpanded(!administrationExpanded)}>
              <div className="Home-content-button-icon">
                <SettingsIcon fill="#108F38" />
              </div>
              <div className="Home-content-button-text">
                Administration
              </div>
              <div className="Home-content-button-trailing">
                <img src={chevronRightIcon} alt=""/>
              </div>
            </div>
            <div className="Home-content-card-content">
              {/*<Link to={"#"} className="Home-account-link">*/}
              <div className="Home-account Home-account-condensed Home-content-button-disabled">
                <div className="Home-account-picture-small">
                  <FormatListBulletedIcon fill="#108F38" height="24px" width="24px" />
                </div>
                <div className="Home-account-info">
                  <div className="Home-account-info-name">Administrer les règles de catégorisation</div>
                </div>
              </div>
              {/*</Link>*/}
              <Link to={"/admin/credit-debit"} className="Home-account-link Home-account-link-simple">
                <div className="Home-account Home-account-condensed">
                  <div className="Home-account-picture-small">
                    <PaidIcon fill="#108F38" height="24px" width="24px" />
                  </div>
                  <div className="Home-account-info">
                    <div className="Home-account-info-name">Créditer / Débiter un compte</div>
                  </div>
                </div>
              </Link>
              {!!(user.rights & 0b0010) && (
                <Link to={"/admin/attribute-points"} className="Home-account-link">
                  <div className="Home-account Home-account-condensed">
                    <div className="Home-account-picture-small">
                      <StarIcon fill="#108F38" height="24px" width="24px" />
                    </div>
                    <div className="Home-account-info">
                      <div className="Home-account-info-name">Attribuer / Retirer des points</div>
                    </div>
                  </div>
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Home;