import './index.css';
import logo from '../../images/ob-logo-512.png';
import { login } from "../../api";

import Input from "./input";

let loginInput = null;
const setLogin = (c) => loginInput = c;
const loginKeypress = (e) => {
  if (e.key === 'Enter') {
    if(passwordInput) {
      passwordInput.focus();
    }
    e.preventDefault();
  }
};

let passwordInput = null;
const setPassword = (c) => passwordInput = c;
const passwordKeypress = (e) => {
  if (e.key === 'Enter') {
    processSubmit(e);
  }
};

const processSubmit = (e) => {
  e.preventDefault();
  if(loginInput && passwordInput) {
    if(!loginInput.value) {
      loginInput.focus();
    } else if(!passwordInput.value) {
      passwordInput.focus();
    } else {
      login(loginInput.value, passwordInput.value);
    }
  }
}

const ERROR_MESSAGES = {
  "SESSION_EXPIRED": "Session expirée. Veuillez vous reconnecter.",
  "NOT_AUTHORIZED": "Accès non autorisé.",
  "INVALID_LOGIN": "Identifiant ou mot de passe incorrect. Veuillez réessayer.",
  "LOGOUT_SUCCESSFUL": "Vous avez été déconnecté avec succès.",
  "LOCAL_LOGOUT_ONLY": "Erreur pendant la déconnexion, déconnexion locale seulement.",
}

function Login({ error }) {
  return (
    <div className="Login">
      <img src={logo} className="Login-logo" alt="logo" />
      {!!error && (
        <div className="Login-error">
          <div className={"Login-error-content" + (error === "SESSION_EXPIRED" || error === "LOCAL_LOGOUT_ONLY" ? " Login-error-warning" : "") + (error === "LOGOUT_SUCCESSFUL" ? " Login-error-success" : "")}>
            {ERROR_MESSAGES[error] || error}
          </div>
        </div>
      )}
      <form onSubmit={processSubmit}>
        <div style={{ marginTop: 16 }}><Input ref={setLogin} id="login" onKeyPress={loginKeypress} placeholder={"Identifiant"} /></div>
        <div style={{ marginTop: 16 }}><Input ref={setPassword} id="password" onKeyPress={passwordKeypress} placeholder={"Mot de passe"} type="password" /></div>
        <div style={{ marginTop: 8 }}><button type="submit" className="Login-button">Se connecter</button></div>
      </form>
      <div style={{ marginTop: 32 }}><a className="Login-forgot-link" href={"/recover"}>Mot de passe oublié ?</a></div>
    </div>
  )
}

export default Login;