import { ReactComponent as LiveTVIcon } from "../images/icons/live_tv_black_24dp.svg";
import { ReactComponent as BeachIcon } from "../images/icons/beach_access_black_24dp.svg";
import { ReactComponent as PeopleAltIcon } from "../images/icons/people_alt_black_24dp.svg";
import { ReactComponent as HelpIcon } from "../images/icons/help_outline_black_24dp.svg";
import { ReactComponent as CurrencyExchangeIcon } from "../images/icons/currency_exchange_black_24dp.svg";
import { ReactComponent as RestaurantIcon } from "../images/icons/restaurant_black_24dp.svg";

export const categories = {
  "activities": {
    "name": "Activités",
    "icon": LiveTVIcon,
  },
  "outdoor": {
    "name": "Sorties",
    "icon": BeachIcon,
    //"color": "red",
  },
  "restaurants": {
    "name": "Restaurants",
    "icon": RestaurantIcon,
  },
  "friends": {
    "name": "Amis",
    "icon": PeopleAltIcon,
    //"color": "red",
  },
  "conversion": {
    "name": "Conversion",
    "icon": CurrencyExchangeIcon,
    //"color": "red",
  },
  "nocat": {
    "name": "Non catégorisé",
    "icon": HelpIcon,
  },
};

export function getCategoryIcon(category) {
  return (category && categories[category] && categories[category].icon) || HelpIcon;
}

export function getCategoryName(category) {
  return (category && categories[category] && categories[category].name) || "Non catégorisé";
}

export function getCategoryColor(category) {
  return (category && categories[category] && categories[category].color) || "#108F38";
}

export function sortCategories(categoriesArray) {
  const indexes = Object.keys(categories);
  categoriesArray.sort((a, b) => indexes.indexOf(a) - indexes.indexOf(b));

  return categoriesArray;
}