import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ScoreChart from "./ScoreChart.js";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

if((window.location.pathname || '').includes('score-chart')) {
    ReactDOM.render(
        <React.StrictMode>
            <ScoreChart/>
        </React.StrictMode>,
        document.getElementById('root')
    );
} else {
    // Render default Bank WepApp
    ReactDOM.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: function(registration) {
    serviceWorkerRegistration.getVersion(registration.waiting).then(version => {
      ReactDOM.render(
        <React.StrictMode>
          <App updateAvailable={version} onUpdate={() => serviceWorkerRegistration.update(registration)} />
        </React.StrictMode>,
        document.getElementById('root')
      );
    });
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
