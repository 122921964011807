import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { ApiProvider, useLoggedIn } from "./api";

import Login from "./pages/login";
import Home from "./pages/home";
import Account from "./pages/account";
import Rewards from "./pages/rewards";
import Grid from "./pages/grid";
import Transfer from "./pages/transfer";
import Profile from "./pages/profile";
import CreditDebit from "./pages/admin/credit-debit";
import AttributePoints from "./pages/admin/attribute-points";
import Update from './components/update';

function App({ updateAvailable, onUpdate }) {
  /* TODO : Add an interval checking last request time and disconnecting if more than SESSION_DELAY seconds */
  return (
    <ApiProvider>
      <div>
        <Routes />
        <Update updateAvailable={updateAvailable} onUpdate={onUpdate} />
      </div>
    </ApiProvider>
  );
}

function Routes() {
  const [loggedIn, loginError] = useLoggedIn();

  return (
    <Router>
      {!loggedIn && (
        <Login error={loginError} />
      )}
      {loggedIn && (
        <Switch>
          <Route path="/account/:id">
            <Account />
          </Route>
          <Route path="/rewards/:id">
            <Rewards />
          </Route>
          <Route path="/grid/:id/:user">
            <Grid />
          </Route>
          <Route path="/grid/:id">
            <Grid />
          </Route>
          <Route path="/transfer">
            <Transfer />
          </Route>
          <Route path="/profile">
            <Profile />
          </Route>
          <Route path="/admin/credit-debit">
            <CreditDebit />
          </Route>
          <Route path="/admin/attribute-points">
            <AttributePoints />
          </Route>
          <Route path="*">
            <Home />
          </Route>
        </Switch>
      )}
    </Router>
  );
}

export default App;
