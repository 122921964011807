import React, { useEffect, useState } from 'react';
import formatBalance from '../../helpers/format-balance';
import { getAccountName, getShowDecimal } from '../../helpers/account-types';
import { encode } from '../../helpers/encode-decode';
import { Link, Redirect, useParams } from "react-router-dom";
import { padLeft } from '../../helpers/pad';
import Loader from "../../components/loader";
import QRCode from 'qrcode.react';

import './index.css';
import { getCategoryColor, getCategoryIcon, getCategoryName } from "../../helpers/categories";
import formatDate from "../../helpers/format-date";
import { getAccounts, getRewardsSummary, getTransactions, getUser } from "../../api";
import { ReactComponent as ArrowBackIcon } from "../../images/icons/arrow_back_black_24dp.svg";
import { ReactComponent as SendIcon } from "../../images/icons/send_black_24dp.svg";
import { ReactComponent as FileDownloadIcon } from "../../images/icons/file_download_black_24dp.svg";
import { ReactComponent as TrendingUpIcon } from "../../images/icons/trending_up_black_24dp.svg";
import { ReactComponent as ExpandMoreIcon } from "../../images/icons/expand_more_black_24dp.svg";
import { ReactComponent as QRCode2Icon } from "../../images/icons/qr_code_2_black_24dp.svg";
import { ReactComponent as CancelIcon } from "../../images/icons/cancel_black_24dp.svg";
import { ReactComponent as RedeemIcon } from "../../images/icons/redeem_black_24dp.svg";
import SavingsIcon from "../../images/outline_savings_black_48dp.png";

function Account() {
  const [user, setUser] = useState(null);
  const [accounts, setAccounts] = useState(null);
  const [transactions, setTransactions] = useState(null);
  const [hasMore, setHasMore] = useState(0);
  const [qrCode, setQrCode] = useState(null);
  const [rewards, setRewards] = useState(null);
  const params = useParams();

  useEffect(() => {
    if(params.id) {
      Promise.all([
        getUser().then(user => () => setUser(user)),
        getAccounts().then(accounts => () => setAccounts(accounts)),
        getTransactions(params.id).then(transactions => () => {
          setTransactions(transactions.result);
          setHasMore(transactions.hasMore);
        }),
        getRewardsSummary(params.id).then(rewards => () => setRewards(rewards)),
        new Promise(accept => setTimeout(() => accept(() => {}), 1000)),
      ]).then(cbs => cbs.forEach(cb => cb()));
    }
  }, [params.id]);

  if(!params.id) {
    return <Redirect to="/" />;
  }

  if(!accounts || !transactions) {
    return (
      <div className="Account">
        <Loader />
      </div>
    );
  }

  const account = accounts.find(a => a.id === params.id);

  if(!account) {
    return <Redirect to="/" />;
  }

  const loadMore = () => {
    const latestTransaction = transactions[transactions.length - 1];
    setHasMore(2);
    getTransactions(params.id, latestTransaction.date).then(result => {
      setTransactions(transactions.concat(result.result));
      setHasMore(result.hasMore);
    });
  }

  const makeQRCode = () => {
    const accountInfo = {
      "n": account.id,
      "u": user.login, // TODO : Not available ?
      "e": user.email,
      "f": user.firstname,
      "l": user.lastname,
      "p": user.picture,
      "o": !!accounts.find(a => a.currency === account.currency && a.id !== account.id),
    };
    setQrCode(encode(btoa(JSON.stringify(accountInfo))));
  }

  const saveQRCode = (e) => {
    const canvas = e.target.parentNode.parentNode.querySelector('canvas');
    if(canvas) {
      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('download', 'qrcode_' + padLeft(account.id, 8) + '.png');
      const dataURL = canvas.toDataURL('image/png');
      const url = dataURL.replace(/^data:image\/png/,'data:application/octet-stream');
      downloadLink.setAttribute('href', url);
      downloadLink.click();
    }
  }

  return (
    <div className="Account">
      <div className="Account-header">
        <div className="Account-header-title">
          <div className="Account-header-title-icon">
            <Link to="/">
              <ArrowBackIcon fill="#FFFFFF" width="32px" height="32px" />
            </Link>
          </div>
          <div className="Account-header-title-text">{getAccountName(account.type)} (n°{padLeft(account.id, 8)})</div>
          <div className="Account-header-title-action" onClick={makeQRCode}>
            <QRCode2Icon fill="#FFFFFF" width="32px" height="32px" />
          </div>
        </div>
        <div className="Account-header-balance">
          {formatBalance(account.balance, getShowDecimal(account.type), account.currency_symbol)}
        </div>
        <div className="Account-header-actions">
          <Link to={{ pathname: "/transfer", state: { fromAccount: account.id }}} className="Account-header-action-link">
            <div className="Account-header-action">
              <div className="Account-header-action-icon"><SendIcon fill="#FFFFFF" width="32px" height="32px" /></div>
              <div className="Account-header-action-text">Virement</div>
            </div>
          </Link>
          <div className="Account-header-action Account-header-action-disabled">
            <div className="Account-header-action-icon"><TrendingUpIcon fill="#FFFFFF" width="32px" height="32px" /></div>
            <div className="Account-header-action-text">Graphique</div>
          </div>
          <div className="Account-header-action Account-header-action-disabled">
            <div className="Account-header-action-icon"><FileDownloadIcon fill="#FFFFFF" width="32px" height="32px" /></div>
            <div className="Account-header-action-text">Export</div>
          </div>
          {rewards && !!rewards.rewards && (
            <Link to={"/rewards/" + account.id} className="Account-header-action-link">
              <div className="Account-header-action">
                <div className="Account-header-action-icon"><RedeemIcon fill="#FFFFFF" width="32px" height="32px" /></div>
                <div className="Account-header-action-text">Récompenses</div>
                {!!rewards.newRewards && (
                  <div className="Account-header-action-badge">{rewards.newRewards}</div>
                )}
              </div>
            </Link>
          )}
        </div>
      </div>
      <div className="Account-content">
        {!transactions.length && (
          <div>Aucune transaction.</div>
        )}
        {transactions.map(transaction => {
          const TransactionIcon = getCategoryIcon(transaction.category);
          const debit = account.id === transaction.source;
          const date = new Date(transaction.date * 1000);
          return (
            <div key={transaction.id} className="Account-transaction">
              <div className="Account-transaction-picture">
                <TransactionIcon fill={getCategoryColor(transaction.category)} height="48px" width="48px" title={getCategoryName(transaction.category)} />
              </div>
              <div className="Account-transaction-info">
                <div className="Account-transaction-info-description">{transaction.description || ("Transaction " + transaction.code)}</div>
                <div className="Account-transaction-info-date">{formatDate(date)}</div>
              </div>
              <div className={"Account-transaction-info-amount " + (debit ? "Account-transaction-info-amount-debit" : "Account-transaction-info-amount-credit")}>{debit ? "-" : "+"}{formatBalance(transaction.amount, getShowDecimal(account.type), account.currency_symbol)}</div>
            </div>
          );
        })}
        {hasMore && hasMore !== 2 && (
          <div className="Account-hasmore" onClick={loadMore}>
            <div className="Account-hasmore-icon">
              <ExpandMoreIcon />
            </div>
            <div className="Account-hasmore-text">
              Cliquez ici pour charger plus.
            </div>
            <div className="Account-hasmore-icon">
              <ExpandMoreIcon />
            </div>
          </div>
        )}
        {hasMore === 2 && (
          <div className="Account-hasmore">
            <div className="Account-hasmore-loading" />
          </div>
        )}
      </div>
      {qrCode && (
        <div className="Account-qrcode">
          <QRCode value={qrCode} size={256} imageSettings={{ src: SavingsIcon, excavate: true, width: 48, height: 48 }} />
          <div className="Account-qrcode-cancel-buttons">
            <FileDownloadIcon fill="#FFFFFF" width="32px" height="32px" onClick={saveQRCode} />
            <div style={{ flex: 1 }} />
            <CancelIcon fill="#FFFFFF" width="32px" height="32px" onClick={() => setQrCode(null)} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Account;