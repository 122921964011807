import React, { useEffect, useState } from 'react';
import formatBalance from '../../helpers/format-balance';
import { getAccountIcon, getAccountName, getShowDecimal } from '../../helpers/account-types';
import { Link, Redirect, useParams } from "react-router-dom";
import { padLeft } from '../../helpers/pad';
import Loader from "../../components/loader";

import './index.css';
import { getCategoryIcon, getCategoryName, sortCategories } from "../../helpers/reward-categories";
import { getIcon } from "../../helpers/icons";
import formatDate from "../../helpers/format-date";
import { getAccounts, getRewards, doBuyReward } from "../../api";
import { ReactComponent as ArrowBackIcon } from "../../images/icons/arrow_back_black_24dp.svg";
import { ReactComponent as MoreVertIcon } from "../../images/icons/more_vert_black_24dp.svg";
import { ReactComponent as TaskAltIcon } from "../../images/icons/task_alt_black_24dp.svg";
import { ReactComponent as ChevronRightIcon } from "../../images/icons/chevron_right_black_24dp.svg";
import { ReactComponent as ChevronLeftIcon } from "../../images/icons/chevron_left_black_24dp.svg";

const ERROR_MESSAGES = {
  "INVALID_INPUT": "Données invalides.",
  "SOURCE_NOT_FOUND": "Le compte source n'a pas été trouvé.",
  "INVALID_REWARD": "La récompense n'a pas été trouvée.",
  "NOT_SAME_CURRENCY": "Les comptes n'utilisent pas la même devise.",
  "INVALID_QUANTITY": "Quantité invalide.",
  "INSUFFICIENT_FUNDS": "Pas assez de fonds.",
  "TARGET_ACCOUNT_NOT_FOUND": "Aucun compte disponible pour la conversion.",
  "INSERT_ERROR": "Erreur inconnue.",
};

function Rewards() {
  const [accounts, setAccounts] = useState(null);
  const [rewards, setRewards] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedReward, setSelectedReward] = useState(null);
  const [doingTransaction, setDoingTransaction] = useState(false);
  const [selectedQuantity, setSelectedQuantity] = useState(1);
  const [transactionError, setTransactionError] = useState(null);
  const [transactionResult, setTransactionResult] = useState(null);
  const params = useParams();

  useEffect(() => {
    if(params.id) {
      Promise.all([
        getAccounts().then(accounts => () => setAccounts(accounts)),
        getRewards(params.id).then(rewards => () => setRewards(rewards)),
        new Promise(accept => setTimeout(() => accept(() => {}), 1000)),
      ]).then(cbs => cbs.forEach(cb => cb()));
    }
  }, [params.id]);

  if(!params.id) {
    return <Redirect to="/" />;
  }

  if(!accounts || !rewards) {
    return (
      <div className="Account">
        <Loader />
      </div>
    );
  }

  const account = accounts.find(a => a.id === params.id);

  if(!account) {
    return <Redirect to="/" />;
  }

  // Confirmation page
  if(transactionResult) {
    const reward = rewards.find(r => r.id === selectedReward);
    return (
      <div className="Transfer">
        <div className="Transfer-header">
          <div className="Transfer-header-title">
            <Link to={"/account/" + params.id}>
              <ArrowBackIcon fill="#FFFFFF" width="32px" height="32px" />
            </Link>
            <div className="Transfer-header-title-text">Récompense attribuée</div>
          </div>
          <div className="Transfer-confirmation">
            <div className="Transfer-confirmation-logo">
              <TaskAltIcon fill="#FFFFFF" width="128px" height="128px" />
            </div>
            <div className="Transfer-confirmation-result">Vous avez acquis la récompense suivante :</div>
            <div className="Transfer-confirmation-result">{reward.name} {selectedQuantity > 1 && ("(x" + selectedQuantity + ")")}</div>
            {!!transactionResult.code && (
              <div className="Transfer-confirmation-text">Votre code de validation est le {transactionResult.code}.</div>
            )}
          </div>
          <Link className="Account-header-action-link" to={"/account/" + params.id}>
            <div className={"Transfer-button"}>Retour</div>
          </Link>
        </div>
      </div>
    );
  }

  // Validation page
  if(selectedReward) {
    const reward = rewards.find(r => r.id === selectedReward);
    const buyReward = () => {
      setDoingTransaction(true);
      Promise.all([
        doBuyReward(params.id, selectedReward, selectedQuantity).catch(error => ({ error })),
        new Promise(accept => setTimeout(() => accept(() => {}), 1500)),
      ]).then(([result]) => {
        setDoingTransaction(false);
        if(result && result.success) {
          setTransactionResult(result);
        } else if (result && result.error) {
          setTransactionError(result.error);
        }
      });
    };

    const AccountIcon = getAccountIcon(account && account.type);
    const selectedAmountFormatted = formatBalance(selectedQuantity * reward.price, getShowDecimal(account.type), account.currency_symbol);
    const RewardIcon = getIcon(reward.icon);

    const decreaseQuantity = () => {
      if(selectedQuantity > 1) {
        setSelectedQuantity(selectedQuantity - 1);
      }
    };
    const increaseQuantity = () => {
      if((selectedQuantity + 1) * reward.price <= account.balance) {
        setSelectedQuantity(selectedQuantity + 1);
      }
    };

    return (
      <div className="Transfer">
        <div className="Transfer-header">
          <div className="Transfer-header-title">
            <div className="Transfer-header-title-icon" onClick={() => setSelectedReward(false)}>
              <ArrowBackIcon fill="#FFFFFF" width="32px" height="32px" />
            </div>
            <div className="Transfer-header-title-text">Sélection d'une récompense</div>
          </div>
          <div className="Transfer-validation">
            <div className="Transfer-validation-contact">
              <div className="Transfer-validation-contact-picture">
                {reward.picture && (
                  <img src={"/img/rewards/" + reward.picture} alt="" title={reward.name} />
                )}
                {!reward.picture && (
                  <RewardIcon fill="#FFFFFF" height="64px" width="64px" title={reward.name} />
                )}
              </div>
              <div className="Transfer-validation-contact-name">
                {reward.name} {selectedQuantity > 1 && ("(x" + selectedQuantity + ")")}
              </div>
              {!!reward.description && (
                <div className="Transfer-validation-contact-email">{reward.description}</div>
              )}
            </div>
            <div className="Transfer-validation-date">Activation le {formatDate(new Date(), "%-d %b %Y")}</div>
            <div className="Reward-validation-quantity">
              <div className={"Reward-validation-arrow" + (selectedQuantity === 1 ? " Reward-validation-arrow-disabled" : "")} onClick={decreaseQuantity}>
                <ChevronLeftIcon fill="#FFFFFF" height="64px" width="64px" />
              </div>
              <div className="Reward-validation-amount">{selectedAmountFormatted}</div>
              <div className={"Reward-validation-arrow" + ((selectedQuantity + 1) * reward.price > account.balance ? " Reward-validation-arrow-disabled" : "")} onClick={increaseQuantity}>
                <ChevronRightIcon fill="#FFFFFF" height="64px" width="64px" />
              </div>
            </div>
            <div className="Transfer-validation-from">
              <div>Depuis :</div>
              <div className="Transfer-account-select-account-info">
                <div className="Transfer-account-select-account-info-picture">
                  <AccountIcon fill="#FFFFFF" height="48px" width="48px" />
                </div>
                <div className="Transfer-account-select-account-info-info">
                  <div className="Transfer-account-select-account-info-name">{getAccountName(account.type)} (n°{padLeft(account.id, 8)})</div>
                  <div className="Transfer-account-select-account-info-balance">{formatBalance(account.balance, getShowDecimal(account.type), account.currency_symbol)}</div>
                </div>
              </div>
            </div>
          </div>
          {!!transactionError && (
            <div className="Transfer-validation-error">
              {(transactionError.code && ERROR_MESSAGES[transactionError.code]) || transactionError.error || "Erreur inconnue."}
            </div>
          )}
          <div className={"Transfer-button"} onClick={buyReward}>Envoyer</div>
        </div>
        {doingTransaction && (
          <div className="Transfer-loader">
            <Loader text={"Transfert en cours..."} goingOut />
          </div>
        )}
      </div>
    );
  }

  const selectReward = (r) => {
    setSelectedQuantity(1);
    setTransactionError(null);
    setSelectedReward(r);
  };

  const categories = rewards.reduce((res, r) => Object.assign(res, { [r.category]: (res[r.category] || 0) + 1 }), {});
  const categoriesShown = [];
  const categoriesOther = [];
  const maxCategories = Math.floor((window.innerWidth - 32) / 70);
  sortCategories(Object.keys(categories)).forEach((c, i, a) => {
    if(a.length <= maxCategories || i < maxCategories - 1) {
      categoriesShown.push(c);
    } else {
      categoriesOther.push(c);
    }
  });

  const currentCategory = selectedCategory || categoriesShown[0];
  const displayedRewards = rewards.filter(r => r.category === currentCategory);

  return (
    <div className="Rewards">
      <div className="Rewards-header">
        <div className="Rewards-header-title">
          <div className="Rewards-header-title-icon">
            <Link to={"/account/" + account.id}>
              <ArrowBackIcon fill="#FFFFFF" width="32px" height="32px" />
            </Link>
          </div>
          <div className="Rewards-header-title-text">Récompenses - {getAccountName(account.type)} (n°{padLeft(account.id, 8)})</div>
        </div>
        <div className="Rewards-header-balance">
          {formatBalance(account.balance, getShowDecimal(account.type), account.currency_symbol)}
        </div>
        <div className="Rewards-header-actions">
          {categoriesShown.map(category => {
            const CategoryIcon = getCategoryIcon(category);
            return (
              <div key={category} className={"Rewards-header-action" + (category === currentCategory ? " Rewards-header-action-selected" : "")} onClick={() => setSelectedCategory(category)}>
                <div className="Rewards-header-action-icon"><CategoryIcon fill="#FFFFFF" height="32px" width="32px" title={getCategoryName(category)} /></div>
                <div className="Rewards-header-action-text">{getCategoryName(category)}</div>
              </div>
            );
          })}
          {!!categoriesOther.length && /* TODO !!! */ (
            <div className="Rewards-header-action" onClick={() => setSelectedCategory("TODO")}>
              <div className="Rewards-header-action-icon"><MoreVertIcon fill="#FFFFFF" height="32px" width="32px" title="Autres catégories" /></div>
              <div className="Rewards-header-action-text">Autres catégories</div>
            </div>
          )}
        </div>
      </div>
      <div className="Rewards-content">
        {!displayedRewards.length && (
          <div>Aucune récompense disponible dans cette catégorie.</div>
        )}
        {displayedRewards.map(reward => {
          const RewardIcon = getIcon(reward.icon);
          const disabled = reward.price > account.balance;
          // TODO : Availability
          return (
            <div key={reward.id} className={"Rewards-reward" + (disabled ? " Rewards-reward-disabled" : "")} onClick={disabled ? undefined : () => selectReward(reward.id)}>
              <div className="Rewards-reward-picture">
                {reward.picture && (
                  <img src={"/img/rewards/" + reward.picture} alt="" title={reward.name} />
                )}
                {!reward.picture && (
                  <RewardIcon fill="#108F38" height="48px" width="48px" title={reward.name} />
                )}
                {reward["is_new"] && (
                  <div className="Rewards-reward-new-badge" />
                )}
              </div>
              <div className="Rewards-reward-info">
                <div className="Rewards-reward-info-name">{reward.name}</div>
                <div className="Rewards-reward-info-description">{reward.description}</div>
              </div>
              <div className="Rewards-reward-info-amount">{formatBalance(reward.price, getShowDecimal(account.type), account.currency_symbol)}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Rewards;